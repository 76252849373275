<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>用户中心</el-breadcrumb-item>
      <el-breadcrumb-item>绑定学员</el-breadcrumb-item>
    </el-breadcrumb>
    <p>亲爱的同学，欢迎登录四季教育学习管理中心！请先绑定您的学生信息：</p>
    <el-form :model="ruleForm"
             :rules="rules"
             ref="ruleForm"
             class="ruleForm"
             label-width="120px"
             label-position="left">
      <el-form-item label="学生姓名"
                    prop="studentname">
        <el-input v-model="ruleForm.studentname"
                  type="text"
                  @input="() => ruleForm.studentname = ruleForm.studentname.trim()"
                  clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="手机号"
                    prop="tel"
                    class="form-item">
        <el-input v-model="ruleForm.tel"
                  clearable>
        </el-input>
      </el-form-item>
      <el-form-item label="短信验证码"
                    prop="yzm"
                    class="msgbox"
                    v-if="showyzm">
        <el-input type="text"
                  v-model="ruleForm.yzm"
                  clearable>
        </el-input>
        <div class="sendmsg">
          <span>|&nbsp;</span>
          <el-button type="text"
                     :disabled="yamFlag"
                     @click.stop="sendMsg">{{yammsg}}</el-button>
        </div>
      </el-form-item>
      <el-button type="primary"
                 @click="submitForm('ruleForm')"
                 :loading="loading"
                 round>绑定</el-button>
    </el-form>
    <div class="list">
      <p class="bold">已绑定学员</p>
      <el-table :data="studentsList"
                style="width: 100%;margin-top:20px"
                :row-class-name="tableRowClassName">
        <el-table-column prop="StudentID"
                         label="学员ID">
        </el-table-column>
        <el-table-column prop="StudentName"
                         label="学生姓名"
                         align="center">
        </el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <span v-if="scope.row.Default==1"
                  class="color1">当前默认</span>
            <span v-else>默认学员</span>
            <el-switch v-model="scope.row.check"
                       active-color="#6f72ff"
                       :disabled="scope.row.Default==1"
                       @change="editdefault($event,scope.row.StudentID)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作"
                         width="200">
          <template slot-scope="scope">
            <div class="handle">
              <p @click="gostuinfo(scope.row)">学生信息</p>
              <p @click="confirmunbind(scope.row.StudentID)">解绑</p>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title=""
               :visible.sync="dialogVisible"
               width="600px"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :show-close="false">
      <choosestu :studentsinfo="studentsinfo"
                  :choosestuTel="choosestuTel"
                 @choosestuFn="choosestuFn"
                 @nochoosestufn="nochoosestufn"></choosestu>
    </el-dialog>

  </div>
</template>
<script>
import { telValidate, smgValidate, nameValidate, checkTel } from '../../utils/util'
import { bind_student_1, send_sms, get_students_info, unbound_student, edit_default_student, bind_student_2 } from '@/api/user'
import choosestu from './chooseStu';
export default {
  name: 'bindstu',
  components: {
    choosestu
  },
  data () {
    return {
      ruleForm: {
        tel: '',
        studentname: '',
        yzm: '',
      },
      rules: {
        tel: telValidate,
        yzm: smgValidate,
        studentname: nameValidate
      },
      yammsg: '发送验证码',
      yamFlag: false,
      loading: false,
      userid: '',
      studentsList: [],
      showyzm: false,
      dialogVisible: false,
      studentsinfo: [],
      choosestuTel:''
    }
  },
  created () {
    sessionStorage.removeItem('stu_userinfo_query')
    this.userid = this.$store.state.user.stu_user_info[0].id;
    this.get_stuinfo()
  },
  methods: {
    //奇偶数背景色
    tableRowClassName ({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return 'even';
      } else if (rowIndex % 2 == 1) {
        return 'odd';
      }
      return '';
    },
    //获取学生列表
    async get_stuinfo () {
      let res = await get_students_info({ userid: this.userid })
      if (res.flag == '1') {
        if(res.datainfo.students_info){
          res.datainfo.students_info.forEach(element => {
            element.check = element.Default == 0 ? false : true
            if (element.Default == 1) {
              this.$store.state.user.stu_students_info = element;
              this.$store.commit("setStuInfo", JSON.stringify(element))
            }
          });
          this.studentsList = res.datainfo.students_info;
           window.clearInterval(this.timer);
           this.showyzm=false
        }else{
          this.studentsList=[]
        }
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }

    },
    //发送验证码
    async sendMsg () {
      if (!checkTel(this.ruleForm.tel.replace(/\s+/g, ''))) {
        this.$message.error('请填写正确的手机号');
        return false
      } else {
        let res = await send_sms({ tel: this.ruleForm.tel, type: 'bind' })
        if (res.flag == '1') {
          this.$message({
            message: '短信发送成功',
            type: 'success'
          });
          this.timeCountDown();
        }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
        } else {
          this.$alert(res.msg, '提示', {
            confirmButtonText: '确定',
            type: 'error',
          });
        }
      }
    },
    //验证码倒计时
    timeCountDown () {
      var time = 60;
      this.timer = setInterval(() => {
        if (time == 0) {
          window.clearInterval(this.timer);
          this.yammsg = "发送验证码";
          this.yamFlag = false;
          return true;
        }
        this.yammsg = time + "S后再发送";
        time--;
        this.yamFlag = true;
        return false;
      }, 1000);
    },
    //绑定
    submitForm (form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          let prames = {
            tel: this.ruleForm.tel,
            studentname: this.ruleForm.studentname,
          }
          if (this.ruleForm.yzm != '') {
            prames.yzm = this.ruleForm.yzm;
            this.bind2(prames)
          } else {
            this.bind1(prames)
          }

        }
      })
    },
    async bind1 (prames) {
      prames.userid = this.userid;
      let res = await bind_student_1(prames)
      if (res.flag == '1') {
        this.$message.success('绑定成功');
        setTimeout(() => {
          this.get_stuinfo()
        }, 1000)
      } else if (res.flag == '2') {
        this.$alert('请验证手机号', '提示', {
          confirmButtonText: '确定',
          type: 'error',
          callback: action => {
            this.showyzm = true
          }
        });
      } else if (res.flag == '3') {
        this.dialogVisible = true;
        this.studentsinfo = res.datainfo.studentsinfo;
        this.choosestuTel="";
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
      this.loading = false
    },
    //选择学生
    choosestuFn (prames) {
      this.bind2(prames)
      this.dialogVisible = false
    },
    //没有选择学生
    nochoosestufn () {
      let prames = {
        tel: this.ruleForm.tel,
        studentname: this.ruleForm.studentname,
      }
      this.bind2(prames)
      this.dialogVisible = false
    },
    //只在绑定学生第一步需要发送手机验证或者需要选择学生的情况下调用此接口
    async bind2 (prames) {
      prames.userid = this.userid;
      let res = await bind_student_2(prames)
      if (res.flag == '1') {
        this.$message.success('绑定成功');
        setTimeout(() => {
          this.get_stuinfo();
          this.$refs.ruleForm.resetFields()
        }, 1000)
      } else if (res.flag == '3') {  //手机号相同
        this.dialogVisible = true;
        this.studentsinfo = res.datainfo.studentsinfo;
        this.choosestuTel=this.ruleForm.tel;
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
      this.loading = false
    },
    //解绑
    confirmunbind (id) {
      this.$confirm('确认解绑?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.unbind(id)
      }).catch(() => {

      });
    },
    // 解绑
    async unbind (id) {
      let prames = {
        userid: this.userid,
        studentid: id
      }
      let res = await unbound_student(prames)
      if (res.flag == '1') {
        this.$message.success('解绑成功');
        setTimeout(() => {
          this.get_stuinfo()
        }, 1000)
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
    //修改默认学生
    async editdefault (val, id) {
      let prames = {
        userid: this.userid,
        studentid: id
      }
      let res = await edit_default_student(prames)
      if (res.flag == '1') {
        this.$message.success('修改成功');
        setTimeout(() => {
          this.get_stuinfo()
          window.location.reload()
        }, 1000)
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }
    },
    gostuinfo (row) {
      this.$router.push({
        path: '/editPerson',
      })
      sessionStorage.setItem('stu_userinfo_query',JSON.stringify(row) )
    },
  },
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.ruleForm {
  margin-top: 40px;
  width: 50%;
  text-align: center;
}
.msgbox {
  position: relative;
  .sendmsg {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 10px;
    .el-button--text {
      color: #6f72ff;
    }
  }
}
.list {
  margin-top: 30px;
}
.studentsinfo {
  li {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .el-input--suffix .el-input__inner {
      border-radius: 20px !important;
    }
  }
}
</style>
<style lang="scss" >
.studentsinfo {
  .el-input--suffix .el-input__inner {
    border-radius: 20px !important;
  }
}
</style>