<template>
  <div>
    <div>
      <p>输入手机号码有以下学员，如需添加该学员请选择并确认点击，都不是请选择不是按钮</p>
      <ul class="studentsinfo" v-if="choosestuTel==''">
        <li v-for="(item,index) in studentsinfo"
            :key="index">
          <el-radio v-model="radioid"
                    :label="item"
                    @change="tablist">{{item.StudentName}}&nbsp;&nbsp;&nbsp; {{item.PhoneNumber1|filterTel(usertel)}}</el-radio>
          <div class="msgbox"
               v-if="(radioid.StudentID==item.StudentID) && (item.PhoneNumber1!=usertel)">
            <el-input type="text"
                      placeholder="验证码"
                      v-model="stuyzm"
                      clearable>
            </el-input>
            <div class="sendmsg">
              <span>|&nbsp;</span>
              <el-button type="text"
                         :disabled="yamFlag"
                         @click.stop="sendMsg">{{yammsg}}</el-button>
            </div>
          </div>
        </li>
      </ul>
       <ul class="studentsinfo" v-else>
        <li v-for="(item,index) in studentsinfo"
            :key="index">
          <el-radio v-model="radioid"
                    :label="item"
                    @change="tablist">{{item.StudentName}}&nbsp;&nbsp;&nbsp; {{item.PhoneNumber1}}</el-radio>
        </li>
      </ul>     
    </div>
    <div slot="footer"
         class="dialogfooter">
      <el-button type="primary"
                 @click="suresub"
                 round>确 定</el-button>
      <el-button @click="nochoosestufn"
                 class="btnborder"
                 round>都没有</el-button>
    </div>
  </div>
</template>
<script>
import { send_sms } from '@/api/user'
export default {
  name: 'bindstu',
  props: {
    studentsinfo: Array,
    choosestuTel:String
  },
  data () {
    return {
      radioid: '',
      stuyzm: '',
      yammsg: '发送验证码',
      yamFlag: false,
      curstupms: {},
      usertel:this.$store.state.user.stu_user_info[0].telphone
    }
  },
  filters: {
    filterTel(tel,usertel){
      var str=tel;
      if(tel!=usertel){
        return str.substring(0,3)+'****'+str.substring(7)
      }else{
        return str
      }
    } 
  },
  methods: {
    //发送验证码
    async sendMsg () {
      let res = await send_sms({ tel: this.curstupms.PhoneNumber1, type: 'bind' })
      if (res.flag == '1') {
        this.$message({
          message: '短信发送成功',
          type: 'success'
        });
        this.timeCountDown();
      }else if(res.flag =='-1'){
        this.$store.dispatch("logout",{
          tips: '登录失效,请重新登录'
        });
      } else {
        this.$alert(res.msg, '提示', {
          confirmButtonText: '确定',
          type: 'error',
        });
      }

    },
    //验证码倒计时
    timeCountDown () {
      var time = 60;
      this.timer = setInterval(() => {
        if (time == 0) {
          window.clearInterval(this.timer);
          this.yammsg = "发送验证码";
          this.yamFlag = false;
          return true;
        }
        this.yammsg = time + "S后再发送";
        time--;
        this.yamFlag = true;
        return false;
      }, 1000);
    },
    //绑定
    suresub () {
      let prms = {
        tel: this.curstupms.PhoneNumber1,
        studentname: this.curstupms.StudentName,
      }
      console.log(this.radioid)
      if (this.radioid == '') {
        this.$message.warning('请选择学生')
        return false
      } else{
        if(this.choosestuTel==''){
          if(this.radioid.PhoneNumber1!=this.usertel){
            if(this.stuyzm==''){
              this.$message.warning('请输入验证码')
              return false
            }else{
              prms.yzm=this.stuyzm
            }
          }
        }
      }
      this.$emit('choosestuFn', prms)
    },
    nochoosestufn () {
      this.$emit('nochoosestufn')
    },
    tablist (val) {
      this.curstupms = val;
    }
  },
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.dialogfooter {
  display: flex;
  width: 80%;
  margin: 40px 10% 0;
  justify-content: space-around;
}
.msgbox {
  position: relative;
  .sendmsg {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 10px;
    .el-button--text {
      color: #6f72ff;
    }
  }
}

.studentsinfo {
  li {
    margin-top: 10px;
    display: flex;
    align-items: center;
    height: 40px;
    .el-input--suffix .el-input__inner {
      border-radius: 20px !important;
    }
  }
}
</style>
<style lang="scss" >
.studentsinfo {
  .el-input--suffix .el-input__inner {
    border-radius: 20px !important;
  }
}
.dialogfooter {
  .btnborder {
    border: 1px solid #6f72ff;
    color: #6f72ff;
  }
}
</style>